import { Link } from "react-router-dom";
import ShapeImg from "../../../assets/images/v1/shape.png";
import Star2Img from "../../../assets/images/v1/star2-orange.png";
function HeroContent() {
	return (
		<div className="hero-content">
			<h1>
				<span style={{ flexWrap: "nowrap" }}>Order & Pickup</span>
				<span className="title-animation">
					Made Easy
					<img src={Star2Img} alt="StarImg" />
				</span>
			</h1>
			<p>
				{`Enhance sales and streamline your ordering and pickup operations. 
				Allow customers to place orders from anywhere, pay
				and choose from curbside pickup, in-store pickup, or other options.`}
			</p>
			<div className="hero-user-wrap">
				<div className="hero-user-data">
					<p>Join other savvy businesses on this transformative journey and reshape the way you engage with your customers.</p>
				</div>
			</div>
			<Link className="call-btn" to="https://business.dishdash.io/">
				Start Your Free Trial  <i className="icon-call"></i>
			</Link>
			<div className="hero-shape">
				<img src={ShapeImg} alt="ShapeImg" />
			</div>
		</div>
	);
}

export default HeroContent;

import Accordion from "./Accordion";
import Content from "./Content";
import Star2Img from "../../../assets/images/v1/star2-orange.png";
import { Link } from "react-router-dom";
function GetStart() {
	return (
		<div className="section">
			<div className="container" style={{ position: "relative" }}>
				<div className="faq-wrap" style={{ display: 'flex', justifyContent: 'start' }}>
					<div className="">
						<div className="d-flex align-items-center">
							<Content />
						</div>
						<div className="">
							<Accordion />
						</div>
						<div className="text-center">
							<Link className="call-btn" to="https://business.dishdash.io/">
								Start Your Free Trial  <i className="icon-call"></i>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default GetStart;

import HeroThumbImg from "../../../assets/images/v1/CurbSide-Pickup-Car.png";
function HeroThumbs() {
	return (
		<div className="hero-thumb jos h-100" data-jos_animation="fade-right">
			<img src={HeroThumbImg} alt="Hero thumb" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
		</div>
	);
}

export default HeroThumbs;

import { Link } from "react-router-dom";
import CheckImg from "../../../assets/images/v3/check.svg";
import Shape1Img from "../../../assets/images/v3/shape1.png";
import Thumb2Img from "../../../assets/images/v3/thumb2.png";
import Star2Img from "../../../assets/images/v1/star2-orange.png";
function Pricing() {
  return (
    <div className=" overflow-hidden mt-5">
      <div className="container">
        <div className="row faq-wrap">
          <div className="col-lg-5 order-lg-1 offset-lg-1">
            <div className="content-thumb ">
              <img src={Thumb2Img} alt="Thumb2Img" />
              <div className="thumb-shape2">
                <img src={Shape1Img} alt="Shape1Img" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="default-content">
              <h2>Increase Revenue With DishDash
                <span>
                  <img src={Star2Img} alt="StarImg" className="d-none d-lg-inline" width={50} style={{ marginLeft: "20px", marginBottom: "10px" }} />
                </span>
              </h2>
              <p>
                Experience the benefits with our no-risk free trial.
                Plans start from just US$19 per month.
              </p>
              <p>
                Our flexible subscription-based pricing is based on the
                number of orders you receive, ensuring you only pay for what you use.
                A free trial applies automatically when you subscribe.
              </p>
              <div className="list-icon">
                <ul>
                  <li>
                    <img src={CheckImg} alt="CheckImg" />
                    No Contract: <span style={{ fontWeight: "normal" }}>Cancel anytime.</span>
                  </li>
                  <li>
                    <img src={CheckImg} alt="CheckImg" />
                    Monthly Pricing: <span style={{ fontWeight: "normal" }}>Automatically applied each month.</span>
                  </li>
                  <li>
                    <img src={CheckImg} alt="CheckImg" />
                    Payment Processing Fee: <span style={{ fontWeight: "normal" }}>Option to pass the online payment processing fee to the consumer.</span>
                  </li>
                </ul>
              </div>
              <Link className="call-btn" to="https://business.dishdash.io/">
                Start Your Free Trial  <i className="icon-call"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;

// import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Layout from "../components/layout/index.jsx";
import ErrorPage from "../error-page";
import BusinessPage from "../page/home/BusinessPage.jsx";
import ConsumersPage from "../page/home/ConsumersPage.jsx";
import BusinessPageLayout from "../components/layout/BusinessPageLayout.jsx";
import ConsumersPageLayout from "../components/layout/ConsumersPageLayout.jsx";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		children: [
			{
				path: "/",
				element: <BusinessPageLayout />,
				children: [
					{
						path: "/",
						element: <BusinessPage />,
					},
					{
						path: "*",
						element: <ErrorPage />,
					},
				],
			},
			{
				path: "/",
				element: <ConsumersPageLayout />,
				children: [
					{
						path: "/consumers-page",
						element: <ConsumersPage />,
					},
				],
			},
		],
	},
]);

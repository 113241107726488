import { Outlet } from "react-router-dom";
import Footer from "../consumers/footer";
import Header from "../consumers/header";
import { Helmet } from "react-helmet-async";
function ConsumersPageLayout() {
	return (
		<>
			<Helmet>
				<title>Ultimate Convenience for Order & Pickup Options | DishDash App</title>
				<meta name="description" content="Order ahead with DishDash for easy and convenient pickup options at your favourite restaurants, cafés, and local shops. Curbside Pickup | In-Store Pickup." />
			</Helmet>
			<Header />
			<Outlet />
			<Footer />
		</>
	);
}

export default ConsumersPageLayout;

import { Outlet } from "react-router-dom";
import Footer from "../business/footer";
import Header from "../business/header";
import { Helmet } from "react-helmet-async";
function BusinessPageLayout() {
	return (
		<>
			<Helmet>
				<title>Order and Pickup Services for Restaurants, Cafés, and More | DishDash Business</title>
				<meta name="description" content="Boost sales and streamline your operations with DishDash. Perfect for restaurants, cafés, pharmacies, and more. Try Curbside, In-Store, Table Ordering, and Counter Pickup." />
			</Helmet>
			<Header />
			<Outlet />
			<Footer />
		</>
	);
}

export default BusinessPageLayout

import youtubeIcon from "../../../assets/images/icon/youtube-dark.png";
import { Link } from "react-router-dom";
function HeaderButton() {
	return (
		<div className="header-btn header-btn-l1 ms-auto d-none d-xs-inline-flex">
			<div className="header-wrap">
				<div className="social-icon header-social">
					<ul>
						<li>
							<a href="https://www.facebook.com/geome.io/" target="_blank">
								<i className="icon-facebook"></i>
							</a>
						</li>
						<li>
							<a href="https://www.instagram.com/geome777/" target="_blank">
								<i className="icon-instagram"></i>
							</a>
						</li>
						<li>
							<a href="https://www.linkedin.com/company/geome" target="_blank">
								<i className="icon-linkedin"></i>
							</a>
						</li>
						<li>
							<a href="https://www.youtube.com/channel/UChbWAEoq7SohcVXz1eO5GkA" target="_blank">
								<img src={youtubeIcon} alt="youtube" width={16} height={16} />
							</a>
						</li>
					</ul>
				</div>
				<Link className="default-btn pill header-btn yellow-btn" to="https://web.dishdash.io/">
					<span className="label-up">Place An Order</span>
					<span className="label-up">Place An Order</span>
				</Link>
			</div>
		</div>
	);
}

export default HeaderButton;
